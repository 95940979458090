
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import { useCookies } from "vue3-cookies";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const { cookies } = useCookies();
    return {
      id: cookies.get("idCookie"),
      // cmpCode: cookies.get("cmpCodeCookie"),
      cmpCode: '1000',
    };
  },
  methods: {},

  setup() {
    const store = useStore();
    const router = useRouter();
    const { cookies } = useCookies();
    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      cmp_code: Yup.string()
        .nullable()
        .required("회사코드를 입력해주세요."),
      user_login_id: Yup.string()
        .nullable()
        .required("아이디를 입력해주세요.")
        .label("Id"),
      password: Yup.string()
        .min(4, "최소 4자리 이상 입력하세요.")
        .required("비밀번호를 입력해주세요.")
        .label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      console.log(values);
      // Clear existing errors
      // store.dispatch(Actions.LOGOUT);
      cookies.set("cmpCodeCookie", values.cmp_code);
      cookies.set("idCookie", values.user_login_id);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      // Send login request

      store.dispatch("login", values);

      // .then(() => {
      //   Swal.fire({
      //     text: "로그인에 성공하였습니다.",
      //     icon: "success",
      //     buttonsStyling: false,
      //     confirmButtonText: "확인",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-primary",
      //     },
      //   }).then(function() {
      //     // Go to page after successfully login
      //     router.push({ name: "dashboard" });
      //   });
      // // })
      // .catch(() => {
      //   Swal.fire({
      //     text: store.getters.getErrors[0],
      //     icon: "error",
      //     buttonsStyling: false,
      //     confirmButtonText: "Try again!",
      //     customClass: {
      //       confirmButton: "btn fw-bold btn-light-danger",
      //     },
      //   });
      // });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
      cookies,
    };
  },
});
